<template>
  <div class="he-r dynamic-margin">
    <div class="dynamic-con">
      <h5 class="he-tit">{{ title }}</h5>
      <div class="concern-box">
        <ul class="concern-list">
          <li v-for="item in fansList" :key="item.index">
            <div class="concer-bg" :style="item.avatar ? { background: 'url(' + item.avatar + ') ' } : {}">
              <div class="con-bgs">
                <span class="concer-img" :style="item.avatar ? { background: 'url(' + item.avatar + ') ' } : {}"></span>
                <div class="concer-info">
                  <h5>
                    {{ item.nickName }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="concer-info-con">
              <p>关注时间：{{ item.createTime.split(' ')[0] }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '我的粉丝',
      level: require('../../assets/images/level.png'),
      fansList: [
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // },
        // {
        //   bg: require('../../assets/images/person.png'),
        //   icon: require('../../assets/images/person.png'),
        //   name: '朱世亮',
        //   level: require('../../assets/images/level.png'),
        //   code: '中幡（建瓯挑幡）',
        //   type: '传统体育,游艺与杂技传承人 ',
        //   jb: '省级第一批'
        // }
      ]
    }
  },
  methods: {
    getFanList() {
      this.axios.get('/api/portal/friend/auth/fancs/page').then(res => {
        this.fansList = res.data.records
      })
    }
  },
  mounted() {
    this.getFanList()
    document.getElementsByTagName('body')[0].className = 'page-body'
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body')
  },

  computed: {}
}
</script>
<style lang="scss" scoped></style>
